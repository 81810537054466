<template>
  <div class="portFilter">
    <ul>
        <li>
            <button @click="handleSegment">
                <CTA segment="All" class="active" />
            </button>
        </li>
        <li>
            <button @click="handleSegment">
            <CTA segment="Development" />
            </button>
        </li>
        <li>
            <button @click="handleSegment">
           <CTA segment="Project Management" />
            </button>
        </li>
        <li>
            <button @click="handleSegment">
            <CTA segment="UAT Testing" />
            </button>
        </li>
         <li>
            <button @click="handleSegment">
            <CTA segment="UIUX" />
            </button>
        </li>
    </ul>
  </div>
</template>

<script setup>
import CTA from '@/components/elements/CTA.vue'

const handleSegment = (e) =>{
    let filterButton = document.querySelectorAll('.portFilter button span');
    filterButton.forEach((el) => {el.classList.remove('active')})
    e.target.classList.add('active')
    let segment = e.target.dataset.segment;
    let portfolioBoxes = document.querySelectorAll('.port-boxes-wrapper li');
   
    portfolioBoxes.forEach((el) => {
        el.classList.add('hide')
        el.classList.remove('active')
         setTimeout(() => {
            if(segment === 'All'){
                el.classList.add('active')
                el.classList.remove('hide')
            }
            else if(el.dataset.segment === segment){
                el.classList.add('active')
                el.classList.remove('hide')
            }
        }, 0)
        
    })
}
</script>

<style>
.portFilter{
    margin-bottom: 1rem;
}
.portFilter ul{
    list-style: none;
    display: flex;
    gap: 1rem;
}
.portFilter ul li{
    width: fit-content;
}
.portFilter ul li button{
    border: none;
    background: transparent;
    width: fit-content;
    padding: 0;
}

</style>