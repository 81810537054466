<template>
  <PortfolioBox :portfolios="portfoliodata" />
</template>

<script setup>
import getPortfolio from '@/composable/getData'
import PortfolioBox from '@/components/PortfolioBox.vue'
import { ref, onMounted } from 'vue'

const portfoliodata = ref([])

onMounted(async () => {
  const data = await getPortfolio.getPortfolio()
  portfoliodata.value = data.sort((a, b) => a.fields.id - b.fields.id).reverse()
})

</script>

<style>
</style>