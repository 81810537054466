import * as contentful from 'contentful'

let getPortfolio = async () => {
    const contentful = require('contentful')

    const client = contentful.createClient({
      space: `${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`,
      environment: 'master', // defaults to 'master' if not set
      accessToken: `${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`
    })
    
    try {
        const response = await client.getEntries()
        return response.items
      } catch (error) {
        console.error(error)
      }
}

export default {getPortfolio}