<template>
  <section class="detailPage">
    <DetailHeader :data="portfoliodata.fields" />
    <FullWidthBanner :src="portfoliodata.fields.fullWidthBannerPath" :alt="portfoliodata.fields.title" />
    <DetailDek v-if="portfoliodata.fields.myRole" :dek="portfoliodata.fields.myRole" number="01" sub="My Role" />
    <IngridBanner v-if="portfoliodata.fields.inGridBannerPath1" :src1="portfoliodata.fields.inGridBannerPath1" :alt1="portfoliodata.fields.title" :src2="portfoliodata.fields.inGridBannerPath2" :alt2="portfoliodata.fields.title" />
    <DetailDek v-if="portfoliodata.fields.result" :dek="portfoliodata.fields.result" number="02" sub="Result" />
  </section>
</template>


<script setup>
import getPortfolio from '@/composable/getData'
import { reactive, computed, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import DetailHeader from '@/components/DetailHeader.vue'
import FullWidthBanner from '@/components/elements/FullWidthBanner.vue'
import DetailDek from '@/components/DetailDek.vue'
import IngridBanner from '@/components/elements/IngridBanner.vue'
const route = useRoute()


const id = computed(() => route.params.id)

const portfoliodata = reactive({
  fields: {}
})
onMounted(async () => {
  const data = await getPortfolio.getPortfolio()

const itemData = data.find(item => item.fields.id === id.value)
    portfoliodata.fields = itemData.fields
})



 

</script>

<style>
.detailPage{
    padding-bottom: 90px;
}
  @media only screen and (max-width: 768px) {
    .detailHeader, .detailDekWrapper{
        flex-wrap: wrap;
    }
    .detailHeaderLeft, .detailDekWrapper{
        max-width: unset;
        padding: 0 2rem;
    }
    .detailHeaderRight{
        padding: 0 2rem;
        margin-top: 4rem;
    }
    .detailHeaderRight, .detailHeaderRight .port-table{
        margin-left: 0;
    }
    .port-ingridBanner{
        gap: 1rem;
    }
  }
</style>