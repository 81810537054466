<template>
  <section class="nav">
    <a href="/">
        <img src="../assets/charmaine_logo.jpg" alt="">
    </a>
  </section>
</template>

<script>

</script>

<style>
.nav{
    height: 3rem;
    background: #f7f7f7;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0 2rem;
    border-bottom: 2px solid #10c9c3;
}

.nav img{
    width: 3rem;
    border-radius: 50%;
    transition: transform 500ms cubic-bezier(0.23, 1.26, 1, 1);
}

.nav img:hover{
    transform: scale(1.1);
}
</style>